import { ElementModifierType } from "src/backend";

export const getIconDocumentName = (args: { documentName: string, modifiers: ElementModifierType[] }) => {

    if (args.modifiers.includes(ElementModifierType.NEEDS_SIGNATURE)) {
        return `${args.documentName}.sign`;
    } else if (args.modifiers.includes(ElementModifierType.FILLABLE_FORM) ||
        args.modifiers.includes(ElementModifierType.SYSTEM_FORM)) {
        return `${args.documentName}.form`;
    }

    return args.documentName;
};