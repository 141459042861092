import type { ListItemProps } from '@mui/material/ListItem';
import ListItem from '@mui/material/ListItem';
import Badge from 'components/dist/atoms/Badge';
import Button from 'components/dist/atoms/Button';
import Stack from 'components/dist/atoms/Stack';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { UrlObject as Url } from 'url';

import { MysherpasTooltip } from '../common/mysherps-tooltip';

interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  chip?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  count?: number;
  sidebarOpen?: boolean;
  disabled?: boolean;
  path?: string | Url;
  title: string;
  tooltip?: boolean;
  // is used to gray out the item, but not disable it
  inactive?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = (props) => {
  const {
    active = false,
    children,
    chip,
    depth,
    icon,
    info,
    count,
    disabled,
    inactive,
    open: openProp = false,
    sidebarOpen,
    path,
    tooltip,
    title,
    sx,
    onClick,
    ...other
  } = props;

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        className="overflow-hidden transition-all w-full max-w-full"
        sx={{
          display: 'block',
          mb: 0.5,
          alignItems: 'flex-start',
          py: 0,
          px: 0,
          pl: 0,
          ml: 0
        }}
        {...other}
      >
        <Button
          block
          disabled={disabled}
          data-ui={!sidebarOpen ? 'active' : 'hidden'}
          className="justify-start font-normal px-2 data-active:pl-2.5 transition-all will-change-auto disabled:opacity-100"
          variant={active ? 'white' : 'ghost'}
          data-testid="sidebar-item-branch">
          <Stack space="sm" row className='flex-1'>
            {icon}
            <div className='data-hidden:opacity-0 overflow-hidden transition-all will-change-auto' data-ui={sidebarOpen ? "active" : "hidden"}>
              {title}
            </div>
          </Stack>
          {info}
        </Button>
        {children}
      </ListItem>
    );
  }

  const content = (<Button
    as={path ? 'a' : 'button'}
    block
    variant={active ? 'white' : 'ghost'}
    onClick={onClick}
    aria-label={title}
    data-ui={`${!sidebarOpen ? 'active' : ''} ${inactive ? 'hidden' : ''}`}
    className="justify-start px-2 data-hidden:opacity-50 data-hidden:hover:opacity-100 font-normal data-active:pl-2.5 transition-all will-change-auto"
    onContextMenu={(event) => {
      onClick && onClick(event);
    }}
    disabled={disabled}

  >
    <Stack space="sm" row className='flex-1'>
      {icon}
      <div className='data-hidden:opacity-0 overflow-hidden transition-all will-change-auto' data-ui={sidebarOpen ? "active" : "hidden"}>
        {title}
      </div>
    </Stack>
    {count > 0 && <Badge
      data-ui={sidebarOpen ? "active" : "hidden"}
      className='data-hidden:absolute transition-all will-change-auto data-hidden:mr-2 data-hidden:mt-2 top-0 right-0 data-hidden:text-yellow-60 data-hidden:p-0 data-hidden:w-2 data-hidden:h-2 overflow-hidden'
      circle
      variant='warning'>
      {count}
    </Badge>}
    {info}
    {chip}
  </Button>)
  // Leaf

  const item = path ? <NextLink href={path} passHref>{content}</NextLink> : content

  return (
    <ListItem
      disableGutters
      className="overflow-hidden transition-all w-full"
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        pl: 0,
        pr: 0,
        ...(sx ?? {})
      }}
      {...other}
    >
      {tooltip ? <MysherpasTooltip
        title={title}
        align="end">
        {item}
      </MysherpasTooltip> : item}
    </ListItem>
  );
};

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string.isRequired
};
