import List from '@mui/material/List';
import Icon from 'components/dist/atoms/Icon';
import Text from 'components/dist/atoms/Text';
import { useRouter } from 'next/router';
import { Role } from 'src/backend';
import { DashboardSidebarItem } from 'src/components/dashboard/dashboard-sidebar-item';
import { TaskGroupIcon } from 'src/components/tasks/task-group-icon';
import { QUERY_MESSAGE_ID, QUERY_MESSAGE_THREAD_ID } from 'src/constants/chat';
import { QUERY_PARAM_PACKAGE_UNASSIGNED_FILTER } from 'src/constants/form-element';
import { KeyStorage, LOAN_TABS, LoanSidebar, QUERY_EXPAND_FILE_PREVIEW, Route, TaskGroup } from 'src/constants/ui';
import { QUERY_PARAM_TASK_VIEW } from 'src/constants/url';
import { Permission } from 'src/constants/user';
import { useActiveLoan } from 'src/hooks/use-active-loan';
import { useConsolidatedTasks } from 'src/hooks/use-consolidated-tasks';
import { useLoanUnreadMessagesCount } from 'src/hooks/use-loan-unread-messages-count';
import { useUser } from 'src/hooks/use-user';
import { useGetKeyPreferenceQuery } from 'src/services/userApi';
import { setExpandedAccordionUserId } from 'src/slices/task';
import { setOpenLoanSidebar, uiSelector } from 'src/slices/ui';
import { useDispatch, useSelector } from 'src/store';
import { TLoanSidebar } from 'src/types/ui';
import { roleCan } from 'src/utils/user/role-can';

import { MysherpasTooltip } from '../common/mysherps-tooltip';

interface Props {
    sidebarOpen?: boolean,
}

export const LoanToolBox = (props: Props) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const { loan } = useActiveLoan();
    const { isLender, userRole } = useUser({
        loan,
    });
    const { data: packageColumnWidthData } = useGetKeyPreferenceQuery<{ data: string | undefined }>({
        key: KeyStorage.PackageColumnWidth
    });
    const unreadMessagesCount = useLoanUnreadMessagesCount({
        loanId: String(router.query.loanId),
        lenderId: loan.lender.id
    })

    const { openSidebar } = useSelector(uiSelector);

    const consolidatedTasksState = useConsolidatedTasks({
        loanId: String(router.query.loanId)
    })

    const handleToggleSidebar = (sidebar: TLoanSidebar) => {
        const isOpen = openSidebar === sidebar;
        dispatch(setExpandedAccordionUserId(null));
        dispatch(setOpenLoanSidebar(isOpen ? null : sidebar));
        router.push({
            pathname: Route.SINGLE_LOAN,
            query: {
                ...router.query,
                tab: LOAN_TABS.PACKAGE,
                [QUERY_EXPAND_FILE_PREVIEW]: packageColumnWidthData ?? 'NORMAL',
                // remove messaging query params
                [QUERY_MESSAGE_THREAD_ID]: undefined,
                [QUERY_MESSAGE_ID]: undefined,
            }
        })
    }

    const handlePackageLinkClick = () => {
        dispatch(setOpenLoanSidebar(null));
        // open the package tab if the user is not in the package tab
        // and set the expand file preview query param to saved value or default to normal
        router.push({
            pathname: Route.SINGLE_LOAN,
            query: {
                ...router.query,
                loanId: router.query.loanId,
                tab: LOAN_TABS.PACKAGE,
                [QUERY_EXPAND_FILE_PREVIEW]: packageColumnWidthData ?? 'NORMAL',
                // remove messaging query params
                [QUERY_MESSAGE_THREAD_ID]: undefined,
                [QUERY_MESSAGE_ID]: undefined,
            }
        })
    }

    const handleOverviewLinkClick = () => {
        dispatch(setOpenLoanSidebar(null));
        router.push({
            pathname: Route.SINGLE_LOAN,
            query: {
                ...router.query,
                loanId: router.query.loanId,
                tab: LOAN_TABS.DETAILS,
                // remove messaging query params
                [QUERY_MESSAGE_THREAD_ID]: undefined,
                [QUERY_MESSAGE_ID]: undefined,
                [QUERY_PARAM_TASK_VIEW]: undefined,
                [QUERY_PARAM_PACKAGE_UNASSIGNED_FILTER]: undefined,
            }
        })
    };

    const onMessagesClick = () => {
        router.push({
            pathname: Route.SINGLE_LOAN_MESSAGES,
            query: {
                loanId: router.query.loanId,
            }
        })
    }

    const handleTasksClick = () => {
        router.push({
            pathname: Route.SINGLE_LOAN_TASKS,
            query: {
                loanId: router.query.loanId,
            }
        })
    }

    const itemsList = getToolsList({
        openSidebar,
        totalMessages: unreadMessagesCount,
        totalTasks: consolidatedTasksState.totalNonShoeboxTasks,
        totalShoeBox: consolidatedTasksState.totalShoeboxTasks,
        tab: String(router.query.tab),
        pathname: router.pathname,
        onToggle: handleToggleSidebar,
        onTasksClick: handleTasksClick,
        onMessagesClick,
        onOverviewClick: handleOverviewLinkClick,
        onPackageClick: handlePackageLinkClick,
        isUserLeadLender: isLender,
        userRole,
    });

    return <List
        disablePadding
        className='transition-all before:content-[" "]  will-change-auto w-full relative before:transition-colors before:h-full before:block before:w-px before:ml-5 before:bg-purple-200 before:absolute before:left-0'
        sx={{
            mb: 1,
            my: 1,
            pl: props.sidebarOpen ? 4 : 1.5,
            borderLeftColor: '#3E4667',
            ...(!props.sidebarOpen ? {
                pl: 0,
                '&::before': {
                    backgroundColor: 'transparent',
                }
            } : {})
        }}>
        <MysherpasTooltip title={`Loan : ${loan?.shortCode} ${loan?.projectName}`}>
            <Text
                as="div"
                truncate
                data-ui={`${props.sidebarOpen ? 'active' : 'hidden'}`}
                className="data-active:pl-1 h-6 data-active:pb-1"
                variant="white" size="xs">
                <Text
                    size="inherit"
                    as="span"
                    variant='inherit'
                    data-ui={`${props.sidebarOpen ? 'active' : 'hidden'}`}
                    className="data-hidden:hidden">Loan: </Text><Text
                        size="inherit"
                        data-ui={`${props.sidebarOpen ? 'active' : 'hidden'}`}
                        className="data-hidden:text-[8px] text-inherit" as="span">{loan?.shortCode}</Text>
            </Text>
        </MysherpasTooltip>
        {itemsList.map(item => {
            const listItem = <DashboardSidebarItem
                key={item.id}
                tooltip={!props.sidebarOpen}
                sidebarOpen={props.sidebarOpen}
                disabled={!router.query.loanId}
                count={item.count}
                open={false}
                active={item.active}
                icon={item.icon}
                title={item.title}
                onClick={item.onClick}
                depth={1} />

            if (item.hidden) {
                return null;
            }

            return listItem;
        })
        }
    </List>
}

interface ToolsListProps {
    openSidebar: TLoanSidebar,
    totalTasks: number,
    pathname: string,
    totalMessages: number,
    totalShoeBox: number
    onToggle: (sidebar: TLoanSidebar) => void,
    onPackageClick: () => void,
    onMessagesClick: () => void,
    onTasksClick: () => void,
    onOverviewClick: () => void,
    isUserLeadLender: boolean,
    tab: string,
    userRole: Role
}

const getToolsList = ({
    openSidebar,
    totalTasks,
    totalMessages,
    onOverviewClick,
    onMessagesClick,
    onPackageClick,
    onTasksClick,
    pathname,
    totalShoeBox,
    tab,
    onToggle,
    isUserLeadLender,
    userRole
}: ToolsListProps) => {
    return [
        {
            id: 0,
            title: 'Overview',
            active: tab === LOAN_TABS.DETAILS,
            count: 0,
            icon: <Icon name="ViewGrid" width={20} height={20} strokeWidth={1.5} />,
            onClick: onOverviewClick,
            ml: 2
        },
        {
            id: 3,
            title: 'Shoebox',
            active: openSidebar === LoanSidebar.TASKS_LIST_SHOE_BOX,
            count: totalShoeBox,
            icon: <TaskGroupIcon type={TaskGroup.ShoeBoxTask} />,
            onClick: (): void => onToggle(LoanSidebar.TASKS_LIST_SHOE_BOX),
            ml: 3,
            hidden: !userRole || !roleCan(userRole, Permission.viewShoeBoxItems)
        },
        {
            id: 1,
            title: 'Package',
            active: tab === LOAN_TABS.PACKAGE,
            count: 0,
            icon: <Icon name="Suitcase" width={20} height={20} strokeWidth={1.5} />,
            onClick: onPackageClick,
            ml: 2
        },
        {
            id: 4,
            title: 'Tasks',
            active: ([Route.SINGLE_LOAN_TASKS, Route.SINGLE_LOAN_TASKS_TYPE] as string[]).includes(pathname),
            count: totalTasks,
            icon: <Icon name="TaskList" width={20} height={20} strokeWidth={1.5} />,
            onClick: onTasksClick,
            ml: 3,
        },
        {
            id: 2,
            title: 'Messages',
            count: totalMessages,
            active: pathname === Route.SINGLE_LOAN_MESSAGES,
            icon: <Icon name="MessageText" width={20} height={20} strokeWidth={1.5} />,
            onClick: onMessagesClick,
            ml: 3,
            hidden: !isUserLeadLender
        },
    ]
}