import type { ListProps } from '@mui/material/List';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';
import { Features } from 'src/backend';
import { ILoanStatus } from 'src/types/loan';

import { UserFeatureGuard } from '../user-feature-guard';
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import { LoanToolBox } from './loan-toolbox';

interface Item {
  path?: string;
  icon?: ReactNode;
  chip?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  feature?: Features;
  inactive?: boolean;
  activeLoan?: boolean;
  title: string;
}

interface DashboardSidebarSectionProps extends ListProps {
  sidebarOpen: boolean;
  contextLoanStatus?: ILoanStatus;
  items: Item[];
  path: string;
  title: string;
  depth?: number;
}

const renderNavItems = ({
  sidebarOpen,
  depth = 0,
  items,
  path,
}: {
  sidebarOpen: boolean;
  depth?: number;
  items: Item[];
  path: string;
}): JSX.Element => (
  <List
    key='list'
    disablePadding
    sx={{
      paddingLeft: (depth > 0 && sidebarOpen) ? 1.5 : 0,
      transformOrigin: 'left top',
      transition: theme => theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      width: '100%'
    }}>
    {items.reduce(
      (acc, item) => reduceChildRoutes({
        sidebarOpen,
        acc,
        item,
        depth,
        path
      }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  sidebarOpen,
  acc,
  item,
  depth,
  disabled = false,
  path,
}: {
  sidebarOpen: boolean;
  acc: JSX.Element[];
  depth: number;
  item: Item;
  disabled?: boolean;
  path: string;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const pathWithoutParams = path.split('?')[0];
  const partialMatch = item.path === '/' ? pathWithoutParams === item.path : pathWithoutParams.includes(item.path);


  if (item.children) {
    acc.push(
      <UserFeatureGuard feature={item.feature} key={key}>
        <DashboardSidebarItem
          tooltip={!sidebarOpen}
          active={partialMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          open={partialMatch}
          sidebarOpen={sidebarOpen}
          path={item.path}
          disabled={disabled}
          title={item.title}
        >
          {renderNavItems({
            sidebarOpen,
            depth: depth + 1,
            items: item.children,
            path
          })}
        </DashboardSidebarItem>
      </UserFeatureGuard>
    );
  } else {
    acc.push(
      <UserFeatureGuard feature={item.feature} key={key}>
        <DashboardSidebarItem
          tooltip={!sidebarOpen}
          active={partialMatch}
          chip={item.chip}
          inactive={item.inactive}
          sidebarOpen={sidebarOpen}
          disabled={disabled}
          depth={depth}
          icon={item.icon}
          info={item.info}
          path={item.path}
          title={item.title}

        />
      </UserFeatureGuard>
    );
    if (item.activeLoan) {
      acc.push(<LoanToolBox
        key='toolbox'
        sidebarOpen={sidebarOpen} />);
    }
  }

  return acc;
};

export const DashboardSidebarSection: FC<DashboardSidebarSectionProps> = (props) => {
  const { items, path, title, depth, sidebarOpen, contextLoanStatus, ...other } = props;
  return (
    <List
      {...other}
      className={depth > 0 ? 'transition-all before:content-[" "]  will-change-auto w-full relative before:transition-colors before:h-full before:block before:w-px before:ml-5 before:bg-purple-200 before:absolute before:left-0' : ''}

      sx={{
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: (sidebarOpen && depth > 0) ? 2.5 : 0,
        '& + &': {
          mt: 2
        },
        ...(!sidebarOpen && {
          '&::before': {
            backgroundColor: 'transparent',
          }
        })
      }}
    >
      {renderNavItems({
        sidebarOpen,
        items,
        path,
        depth
      })}
    </List>
  );
};

DashboardSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
