import { SharePermissionType } from "src/backend";
import { AssignAvatarProps } from "src/components/form-elements/assign-avatar/assign-avatar.types";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";

import { isRoleABorrower } from "../user/is-role-a-borrower";

export const getAssignAvatarMode = (sharedInfo: FormElementV2ResponseDtoExtended['sharedInfo']): AssignAvatarProps["mode"] => {
    if (!sharedInfo) {
        return "DEFAULT";
    }
    // filter out LINK permission
    const sharedInfoWithoutLink = sharedInfo
        .filter((info) => !info.permissions.includes(SharePermissionType.LINK));
    // get all users roles in sharedInfoWithoutLink
    const defaultRoles = sharedInfoWithoutLink
        .map((info) => info.sharedWithUser.roleDefault)
        // filter out undefined roles
        .filter((role) => !!role);

    if (sharedInfoWithoutLink.length === 1) {
        // if sharedInfoWithoutLink has only one user, then mode is DEFAULT
        return defaultRoles.some(isRoleABorrower) ? "BORROWER" : "LENDER";
    }

    if (sharedInfoWithoutLink.length > 1) {

        // if sharedInfoWithoutLink has users with both defaultRole Borrower and Lender, then mode is MIXED
        // if sharedInfoWithoutLink has users with only defaultRole Borrower, then mode is BORROWER
        // if sharedInfoWithoutLink has users with only defaultRole Lender, then mode is LENDER

        if (defaultRoles.some(isRoleABorrower) && defaultRoles.some(role => !isRoleABorrower(role))) {
            return "MIXED";
        } else if (defaultRoles.some(isRoleABorrower)) {
            return "BORROWERS";
        } else if (!defaultRoles.some(isRoleABorrower)) {
            return "LENDERS";
        }
    }

    return "DEFAULT"
};