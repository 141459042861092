import { useState } from "react";
import { ElementStatusType, FormElementV2RequestDto, FormElementV2ResponseDto, LoanDto, NoteObjectType, NoteType, NoteVisibilityType } from "src/backend";
import { useUser } from "src/hooks/use-user";
import { useCreateNoteMutation } from "src/services/noteApi";
import { useUpdateElementsMutation } from "src/services/packageApi";
import { getLoanFormElements } from "src/slices/form-element";
import { useDispatch } from "src/store";
import { canUserIdLoanRoleAcceptElements } from "src/utils/form-element/can-user-id-loan-role-accept-elements";

export interface FormElementStatusProps {
    id: string;
    loanId: string;
    loanRoles: LoanDto['loanRoles'];
    withPortal?: boolean;
    hidden: boolean;
    title: string;
    status: FormElementV2ResponseDto['status'];
}

export const useFormElementStatusState = (props: FormElementStatusProps) => {
    const [isRejectConfirmOpen, setIsRejectConfirmOpen] = useState(false);
    const { user } = useUser();
    const [updateElements] = useUpdateElementsMutation();
    const [createNote] = useCreateNoteMutation();
    const dispatch = useDispatch();

    const handleCreateNote = async (args: { note: string, includeWithMessage: boolean }) => {
        createNote({
            content: args.note,
            authorId: user.id,
            id: null,
            includeWithMessages: args.includeWithMessage,
            visibility: args.includeWithMessage ? NoteVisibilityType.ALL : NoteVisibilityType.LENDER_TEAM,
            loanId: props.loanId,
            objectId: props.id,
            noteType: NoteType.REJECTION,
            objectType: NoteObjectType.PACKAGE_INFO,
        });
    };

    const handleStatusChangeConfirm = async (args: { status: ElementStatusType, note?: string, includeWithMessage?: boolean }) => {
        const payload: Partial<FormElementV2RequestDto> & { loanId: string, id: string } = {
            loanId: props.loanId,
            id: props.id,
        }
        if (args.status === ElementStatusType.REJECTED) {
            payload['rejectedByUserId'] = user.id;
            payload['rejected'] = true;
        } else if (args.status === ElementStatusType.ACCEPTED) {
            payload['approvedByUserId'] = user.id;
        } else {
            payload['status'] = args.status;
        }
        if (args.note) {
            await handleCreateNote({
                note: args.note,
                includeWithMessage: args.includeWithMessage,
            });
        }
        await updateElements({
            multiSelect: false,
            elements: [payload],
        });
        dispatch(getLoanFormElements(props.loanId, true));
    }

    const handleStatusChange = async (status: ElementStatusType) => {
        if (status === ElementStatusType.REJECTED) {
            setIsRejectConfirmOpen(true);
        } else {
            await handleStatusChangeConfirm({ status });
        }
    }

    const mainStatusIcon = ![ElementStatusType.ACCEPTED].includes(props.status)
        ? ElementStatusType.ACCEPTED
        : ElementStatusType.REVIEWING;

    const hasMenu = !!StatusMap[props.status]

    const menuItems = StatusMap[props.status] || [];

    const isAcceptStatusEnabled = canUserIdLoanRoleAcceptElements(user.roleDefault, user.id, props.loanRoles);

    const filteredMenuItems = menuItems.filter(status => status !== ElementStatusType.ACCEPTED || isAcceptStatusEnabled);

    return {
        isRejectConfirmOpen,
        handleStatusChange,
        setIsRejectConfirmOpen,
        handleStatusChangeConfirm,
        mainStatusIcon,
        hasMenu,
        menuItems: filteredMenuItems,
    } as const;
}

const StatusMap: Record<ElementStatusType, ElementStatusType[]> = {
    [ElementStatusType.OPEN]: [],
    [ElementStatusType.ACCEPTED]: [
        ElementStatusType.REVIEWING,
        ElementStatusType.REJECTED,
        ElementStatusType.NEEDS_LEGAL_REVIEW,
    ],
    [ElementStatusType.REVIEWING]: [
        ElementStatusType.ACCEPTED,
        ElementStatusType.REJECTED,
    ],
    [ElementStatusType.NEEDS_ATTENTION]: [
        ElementStatusType.ACCEPTED,
        ElementStatusType.REJECTED,
    ],
    [ElementStatusType.REJECTED]: [
        ElementStatusType.ACCEPTED,
        ElementStatusType.REVIEWING,
    ],
    [ElementStatusType.NEEDS_LEGAL_REVIEW]: [
        ElementStatusType.ACCEPTED,
        ElementStatusType.REJECTED,
    ],
    [ElementStatusType.SUBMITTED]: [
        ElementStatusType.ACCEPTED,
        ElementStatusType.REVIEWING,
        ElementStatusType.REJECTED,
    ],
    [ElementStatusType.IN_PROGRESS]: [
        ElementStatusType.ACCEPTED,
        ElementStatusType.REJECTED,
        ElementStatusType.REVIEWING,
    ],
}

