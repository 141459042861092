import { useContext } from 'react';
import { useGetAppVersionQuery } from 'src/services/appApi';


export const useSettings = () => {
    const { data = '' } = useGetAppVersionQuery();

    return {
        version: `v${data?.split('-')?.[0]}`
    }
}
