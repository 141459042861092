import { LoanPhaseCategoryType } from "src/backend";
import { useGetUnreadCountByCompanyQuery } from "src/services/messageApi";

import { useUser } from "./use-user";

export const useLoanUnreadMessagesCount = (args: { lenderId: string, loanId: string }) => {
    const userState = useUser();
    const categories = [
        LoanPhaseCategoryType.LEAD,
        LoanPhaseCategoryType.ORIGINATION,
        LoanPhaseCategoryType.PORTFOLIO,
    ];
    if (userState.isLender) {
        categories.push(LoanPhaseCategoryType.ARCHIVE);
    }
    const { data } = useGetUnreadCountByCompanyQuery({
        companyId: args.lenderId,
        categories
    }, {
        skip: !args.lenderId,
    });
    const unreadMessagesCount = data?.unreadMap?.[args.loanId] ?? 0;
    return unreadMessagesCount;
}