import type { FC, ReactNode } from 'react';
import { Features } from 'src/backend';
import { useUser } from 'src/hooks/use-user';

interface UserFeatureGuardProps {
    feature?: Features;
    children: ReactNode;
}

export const UserFeatureGuard: FC<UserFeatureGuardProps> = (props) => {
    const { children, feature } = props;
    const { features } = useUser();

    if (feature && !features.includes(feature)) {
        return null;
    }

    return <>{children}</>;
};

